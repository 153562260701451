/**
 * This function adds a non-breaking space to the end of a string.
 * This is useful for preventing widows in text.
 */
export function addNonBreakingSpace(string) {
  if (string.split(' ').length < 2) return string;

  const stringArr = string.split('');
  const lastSpaceIndex = stringArr.lastIndexOf(' ');
  stringArr.splice(lastSpaceIndex, 1, '\u00A0');
  return stringArr.join('');
}

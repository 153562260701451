/**
 * CommonJS because this file is used in the Gatsby Node API
 */

/**
 * Grabbing all the categories from the articles, removing duplicates and null or undefined values.
 * @param {Article[]} articles
 * @returns {Category[]}
 */

const getUniqueCategoriesFromArticles = (articles) => {
  return articles
    .map((article) => article.categories)
    .flatMap((category) => category)
    .filter((category, index, self) => index === self.findIndex((c) => c === category) && category)
    .sort();
};

/**
 *
 * @param {Category[]} categories
 * @param {Article[]} articles
 * @returns {[{title: string, articles: Article[]}]}
 */

const sortArticlesByCategory = (categories, articles) => {
  return categories.map((category) => ({
    title: category,
    articles: articles.filter((article) => article.categories?.includes(category)),
  }));
};

module.exports = {
  getUniqueCategoriesFromArticles,
  sortArticlesByCategory,
};

import { Link } from 'gatsby';
const ArticleLink = ({ href, children }) => {
  const isExternalLink = href?.startsWith('http');
  const styles = {
    color: 'UIGray3',
    fontWeight: 'medium',
    display: 'inline-block',
    textDecoration: 'underline',
    ':hover': {
      textDecorationColor: 'UIGray3',
    },
    ':active': {
      color: 'UIGray2',
      textDecorationColor: 'UIGray2',
    },
  };

  if (isExternalLink) {
    return (
      <a target="_blank" rel="noreferrer noopener" sx={{ ...styles }} href={href}>
        {children}
      </a>
    );
  }
  return (
    <Link sx={{ ...styles }} to={href}>
      {children}
    </Link>
  );
};

export default ArticleLink;

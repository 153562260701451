import PropTypes from 'prop-types';
import { Text, Grid, Box } from 'theme-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Author = ({ author }) => {
  if (!author) return null;
  const { name, title, image } = author;

  return (
    <Grid
      sx={{
        fontSize: '1.4rem',
        lineHeight: 1.15,
        textAlign: 'center',
        letterSpacing: '-0.01em',
        gap: '1.6rem',
        placeItems: 'center',
      }}
    >
      {image && (
        <GatsbyImage
          image={getImage(image)}
          sx={{ borderRadius: '100%', width: '4.8rem', height: '4.8rem', isolation: 'isolate' }}
        />
      )}
      <Box>
        <Text sx={{ display: 'block', fontWeight: 'medium', mb: '0.2rem' }}>{name}</Text>
        <Text sx={{ display: 'block' }}>{title}</Text>
      </Box>
    </Grid>
  );
};

Author.propTypes = {
  name: PropTypes.string,
};

export default Author;

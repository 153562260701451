const ArticleH2 = ({ children }) => {
  return (
    <h2
      sx={{
        px: ['2.4rem', '4rem'],
        fontSize: ['2rem', '2.8rem'],
        lineHeight: 1,
        letterSpacing: '-0.02em',
        mb: '1.6rem',
        mt: '4.8rem',
      }}
    >
      {children}
    </h2>
  );
};

export default ArticleH2;

import AnimatedProductCard from '~/components/Generic/AnimatedProductCard';
import { Grid, Box } from 'theme-ui';
import useMatchMedia from '~/hooks/utils/use-match-media';

const StickyLink = ({ productLink }) => {
  /**
   * TODO: make this smarter
   * */
  const topMargin = 90 + 44 + 48; //9rem for nav, 4.4rem for notification, 4.8 for go back
  const { products, productsToShowOnMobile } = productLink;

  const isMobile = useMatchMedia('mobile');
  const isTablet = useMatchMedia('tablet');

  if (!products || products.length === 0) return null;
  const indexesToShow = productsToShowOnMobile?.map((index) => parseInt(index, 10) - 1);

  return (
    <Box sx={{ height: '100%' }}>
      <Grid
        sx={{
          justifySelf: 'center',
          position: ['static', null, 'sticky'],
          top: [null, null, topMargin],
          gap: '2.4rem',
          px: ['2.4rem', '4rem'],
          py: ['1.2rem', null, '4.8rem'],
          alignContent: 'center',
        }}
      >
        {products.map((product, index) => {
          if ((isMobile || isTablet) && indexesToShow && !indexesToShow.includes(index)) return null;
          const { additionalContentImage, mainImage, name, slug } = product;

          // if collection array has 2 items, it's unisex for now.
          const isUnisex = product?.collection?.length === 2;
          const collectionSlug = product?.collection?.[0]?.slug ?? 'products';
          const collectionName = isUnisex ? 'unisex' : collectionSlug.slice(0, -1);
          return (
            <AnimatedProductCard
              key={product.id}
              // Kits don't have additionalContentImage
              image={additionalContentImage ?? mainImage}
              locationDetail="blog_product_link"
              collectionSlug={collectionSlug}
              collectionName={collectionName}
              productName={name}
              productSlug={slug}
              showCollection={true}
              mobileLayoutOnTablet={true}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default StickyLink;

import { Text, Flex } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';
import { motion } from 'framer-motion';
import LinkRenderer from '~/components/Generic/LinkRenderer';

const AnimatedProductCard = ({
  productSlug,
  image,
  collectionSlug,
  linkRendererQueryString,
  productName,
  locationDetail,
  collectionName,
  mobileLayoutOnTablet,
}) => {
  const animateItem = {
    hidden: {
      opacity: 0,
      skewY: 5,
      translateY: -100,
      scaleY: 1.2,
      transformOrigin: 'center',
    },
    show: {
      opacity: 1,
      skewY: 0,
      translateY: 0,
      scaleY: 1,
      transition: {
        ease: 'anticipate',
        skewY: {
          delay: 0.1,
          type: 'easeIn',
        },
      },
    },
  };

  return (
    <LinkRenderer
      type="product"
      productSlug={productSlug}
      collectionSlug={collectionSlug}
      qs={linkRendererQueryString}
      productName={productName}
      locationDetail={locationDetail}
    >
      <motion.div
        variants={animateItem}
        whileHover={{
          scale: 1.03,
          borderColor: 'var(--color)',
          transition: { type: 'ease' },
        }}
        whileTap={{
          scale: 1.03,
          borderColor: 'var(--color)',
          transition: { type: 'ease' },
        }}
        sx={(t) => ({
          border: `1px solid ${t.colors.UIGray2}`,
          padding: ['8px', mobileLayoutOnTablet ? null : '12px', '12px'],
          width: ['100%', mobileLayoutOnTablet ? null : 'fit-content', 'fit-content'],
          borderRadius: '12px',
          display: 'flex',
          flexDirection: ['row', mobileLayoutOnTablet ? null : 'column', 'column'],
          gap: ['1.6rem', mobileLayoutOnTablet ? null : 0, 0],
          alignItems: 'center',
        })}
      >
        <div
          sx={{
            position: 'relative',
            width: ['6.4rem', mobileLayoutOnTablet ? null : '18rem', '18rem'],
            borderRadius: '4px',
            overflow: 'hidden',
            WebkitMaskImage: '-webkit-radial-gradient(white, black)',
            mb: [0, mobileLayoutOnTablet ? null : '1.6rem', '1.6rem'],
          }}
        >
          <div
            sx={{
              height: '0%',
              p: 0,
              pb: '100%',
              width: '100%',
              position: 'relative',
              backgroundColor: 'cloud',
            }}
          >
            <ImgLoader forwardSx={{ position: 'absolute', top: 0, width: '100%', height: '100%' }} image={image} />
          </div>
        </div>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: ['flex-start', mobileLayoutOnTablet ? null : 'center', 'center'],
            gap: '0.4rem',
          }}
        >
          <Text
            sx={{
              textTransform: 'uppercase',
              fontSize: '1.8rem',
              fontWeight: collectionName ? 700 : 500, //creates contrast if collection is shown
              lineHeight: 1,
            }}
          >
            {productName}
          </Text>
          {collectionName && (
            <Text sx={{ textTransform: 'uppercase', fontSize: '1.6rem', fontWeight: 'medium', lineHeight: 1 }}>
              {collectionName}
            </Text>
          )}
        </Flex>
      </motion.div>
    </LinkRenderer>
  );
};

export default AnimatedProductCard;

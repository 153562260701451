const ArticleUnorderedList = ({ children }) => {
  return (
    <ul
      sx={{
        px: ['2.4rem', '4rem'],
        fontSize: ['1.4rem', '1.6rem'],
        lineHeight: [1.4, 1.5],
        letterSpacing: '-0.01em ',
        mt: 0,
        '&:last-of-type, &:only-of-type ': {
          mb: '4.8rem',
        },
      }}
    >
      {children}
    </ul>
  );
};

export default ArticleUnorderedList;

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useRef } from 'react';
const ArticleImage = ({ image, isCarousel, isFeatured, forwardSx, backgroundColor }) => {
  const container = useRef(null);
  // This could also come from better calculation and/or CMS
  const getAspectRatio = () => {
    if (isCarousel) return '4/3';
    if (isFeatured) return '16/9';
    return 'none';
  };

  if (!image) return null;

  const { description } = image;

  return (
    <figure
      ref={container}
      sx={{
        placeSelf: isFeatured ? 'center' : null,
        px: ['2.4rem', '4rem'],
        mb: isCarousel ? 0 : '4.8rem',
        mx: isFeatured ? 'auto' : 0,
        backgroundColor: backgroundColor ?? 'transparent',
        ...forwardSx,
      }}
    >
      <GatsbyImage
        image={getImage(image)}
        alt={image?.title}
        sx={{
          aspectRatio: getAspectRatio(),
          borderRadius: isFeatured && '1.6rem',
          overflow: 'hidden',
          isolation: 'isolate',
          width: '100%',
        }}
      />
      {description && (
        <figcaption sx={{ fontSize: '1.4rem', lineHeight: 1.15, color: 'UIGray2', mt: '1.6rem' }}>
          {description}
        </figcaption>
      )}
    </figure>
  );
};

export default ArticleImage;

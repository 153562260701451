import { graphql, useStaticQuery } from 'gatsby';
import { getUniqueCategoriesFromArticles } from '~/utils/blog-category/index.cjs';

export const useBlogCategories = () => {
  const data = useStaticQuery(graphql`
    query allCategories {
      allContentfulArticle(filter: { article_gallery: { elemMatch: { slug: { eq: "blog" } } } }) {
        nodes {
          categories
        }
      }
    }
  `);

  const categories = getUniqueCategoriesFromArticles(data.allContentfulArticle.nodes);

  return categories;
};

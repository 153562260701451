/**
 * This component is used to render TikTok videos in the article body.
 * It does not follow the documentation for embedding TikTok videos.
 * The implementation suggested here: https://developers.tiktok.com/doc/embed-videos/
 * does not work with Gatsby or React even with the gatsby-plugin-tiktok plugin.
 * The width and height should fit the TikTok video aspect ratio.
 */
const ArticleTikTokVideo = ({ tiktokVideoId }) => {
  return (
    <div sx={{ width: '100%', display: 'grid', placeItems: 'center', mb: '4.8rem' }}>
      <iframe
        width={323}
        height={738}
        src={`https://www.tiktok.com/embed/v2/${tiktokVideoId}`}
        frameborder="0"
        title="TikTok Video"
      ></iframe>
    </div>
  );
};

export default ArticleTikTokVideo;

import React from 'react';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ArticlePage from '~/components/Article/Page';
import { addNonBreakingSpace } from '~/utils/add-non-breaking-space';
import { useBlogCategories } from '~/hooks/graphql/queries/use-blog-categories';

const ArticlePageTemplate = React.forwardRef(({ data, location }, ref) => {
  const { article } = data;
  const articleWithNonBreakingTitle = {
    ...article,
    title: addNonBreakingSpace(article.title),
  };
  const allCategories = useBlogCategories();
  return (
    <Layout>
      <Metadata title={article.title} description={article.metaDescription} image={article.image.url} />
      <ArticlePage article={articleWithNonBreakingTitle} ref={ref} location={location} allCategories={allCategories} />
    </Layout>
  );
});

export const pageQuery = graphql`
  query ArticlePage($slug: String!, $locale: String) {
    article: contentfulArticle(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ArticlePageFragment
    }
  }
`;

export default ArticlePageTemplate;

import PropTypes from 'prop-types';
import { Box, Flex, Grid, Text, Heading } from 'theme-ui';
import ArticleCard from '../Card';
import TwitterIcon from '~/assets/images/icons/twitter.svg';
import FacebookIcon from '~/assets/images/icons/facebook.svg';
import EmailIcon from '~/assets/images/icons/email.svg';
import LinkedInIcon from '~/assets/images/logos/linkedin.svg';
import ArticleBody from './Body';
import ArticleImage from './Body/Image';
import ArticleHeader from './Header';
import SocialLink from './SocialLink';
import CategoryBar from '~/components/Blog/Category/Bar';

const ArticlePage = ({ article, location, allCategories }) => {
  const {
    title,
    slug,
    author,
    image,
    categories,
    publishDate,
    relatedArticles,
    articleAttribution,
    richText,
    productLinks,
  } = article;

  return (
    <Box
      sx={{
        '--go-back-height': '4.8rem',
        position: 'relative',
        backgroundColor: 'backgroundGrey',
      }}
    >
      <CategoryBar location={location} categories={allCategories} />
      <Box
        sx={{
          backgroundColor: 'backgroundGrey',
        }}
      >
        <ArticleHeader author={author} categories={categories} publishDate={publishDate} title={title} />
        <Grid sx={{ placeItems: 'center', width: '100%' }}>
          <ArticleImage image={image} isFeatured />
        </Grid>
        <ArticleBody body={richText} productLinks={productLinks} slug={slug} />

        <Grid
          sx={{
            gap: ['4.8rem', '9.6rem'],
            my: ['4.8rem', '9.6rem'],
          }}
        >
          {/* TODO: Extract each part in a component */}
          {articleAttribution && (
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'cardGrey',
                py: ['3.2rem', '6.4rem'],
                px: '2.4rem',
              }}
            >
              <Text
                sx={{
                  maxWidth: '66.8rem',
                  fontSize: '1.6rem',
                  lineHeight: 1.5,
                  letterSpacing: '-0.01em',
                  fontWeight: 'medium',
                  textAlign: 'center',
                  p: { my: 0 },
                }}
                dangerouslySetInnerHTML={{ __html: articleAttribution?.childMarkdownRemark?.html }}
              />
            </Flex>
          )}
          <Flex
            sx={{
              justifySelf: 'center',
              flexDirection: 'column',
              justifyItems: 'center',
              gap: '1.6rem',
              px: ['2.4rem', '4rem'],
            }}
          >
            <Heading sx={{ fontSize: '1.6rem', textAlign: 'center' }}>Share This Story</Heading>
            <Flex
              sx={{
                alignItems: 'center',
                gap: '2.4rem',
              }}
            >
              <SocialLink socialPlatform="twitter" location={location} title={title}>
                <TwitterIcon sx={{ path: { fill: 'black' } }} />
              </SocialLink>
              <SocialLink socialPlatform="facebook" location={location} title={title}>
                <FacebookIcon sx={{ path: { height: '2.4rem', width: '2.4rem' } }} />
              </SocialLink>
              <SocialLink socialPlatform="email" location={location} title={title}>
                <EmailIcon />
              </SocialLink>
              <SocialLink socialPlatform="linkedin" location={location} title={title}>
                <LinkedInIcon
                  sx={{
                    height: '2.4rem',
                    width: '2.4rem',
                  }}
                />
              </SocialLink>
            </Flex>
          </Flex>
          {relatedArticles && relatedArticles.length > 0 && (
            <Box sx={{ px: ['2.4rem', '4rem'] }}>
              <Heading
                sx={{
                  textAlign: 'center',
                  mb: ['1.6rem', '2.4rem '],
                  fontSize: ['2.8rem', '3.6rem'],
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                  lineHeight: 0.9,
                  letterSpacing: '-0.03em',
                }}
              >
                Related Articles
              </Heading>
              <Grid sx={{ gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'] }}>
                {relatedArticles.map((article) => (
                  <ArticleCard article={article} key={article.id} />
                ))}
              </Grid>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

// TODO: update prop types
ArticlePage.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string.isRequired,
    body: PropTypes.shape({}),
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    author: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
      bio: PropTypes.shape({}),
    }),
  }),
};

export default ArticlePage;

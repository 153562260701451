import { Grid, Text, Heading } from 'theme-ui';
import Author from '~/components/Article/Author';
import format from 'date-fns/format';
import Categories from '../Card/Categories';

const ArticleHeader = ({ categories, title, author, publishDate }) => {
  const date = format(new Date(publishDate), 'MMMM yyyy');

  return (
    <Grid
      sx={{
        gridColumn: '2/3',
        justifyItems: 'center',
        gap: '1.6rem',
        maxWidth: '66.8rem',
        mx: 'auto',
        mb: '4.8rem',
        mt: ['calc(4.8rem + var(--go-back-height))', 'calc(9.6rem + var(--go-back-height))'],
        px: ['2.4rem', '4rem'],
      }}
    >
      <Categories categories={categories} />
      <Heading
        as="h1"
        sx={{
          fontSize: ['2.8rem', '3.6rem'],
          lineHeight: 1,
          textAlign: 'center',
          letterSpacing: '-0.02em',
        }}
      >
        {title}
      </Heading>
      <Text
        sx={{
          display: 'block',
          fontSize: '1.4rem',
          fontWeight: 'medium',
          lineHeight: 1.15,
          letterSpacing: '-0.01em',
          textTransform: 'uppercase',
          color: 'UIGray2',
        }}
      >
        {date}
      </Text>
      <Author author={author} />
    </Grid>
  );
};

export default ArticleHeader;

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import ArticleParagraph from './Paragraph';
import ArticleImage from './Image';
// import ArticleCarousel from './Carousel';
import ArticleH2 from './H2';
import StickyLink from '../StickyLink';
import ArticleLink from './Link';
import ArticleMuxVideo from './Video/Mux';
import ArticleYouTubeVideo from './Video/YouTube';
import ArticleTikTokVideo from './Video/TikTok';
import ArticleOrderedList from './OrderedList';
import ArticleListItem from './ListItem';
import ArticleUnorderedList from './UnorderedList';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import ArticleH3 from './H3';
const isValidChildren = (children) => Array.isArray(children) && children.some((child) => /\w/gi.test(child));

/**
 * Options for rendering Contentful rich text.
 * Note the way we render LIST_ITEM. Contentful injects a paragraph tag
 * inside each list item, which we don't want. So we use the solution suggested
 * here: https://github.com/contentful/rich-text/issues/126#issuecomment-636926522
 * Using documentToReactComponents() at the top level would mess up the videos and images.
 *
 */
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong sx={{ fontWeight: 'medium' }}>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em sx={{ fontStyle: 'italic' }}>{text}</em>,
    [MARKS.SUPERSCRIPT]: (text) => <sup>{text}</sup>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) =>
      isValidChildren(children) && <ArticleLink href={data.uri}>{children}</ArticleLink>,
    [BLOCKS.HEADING_2]: (_, children) => isValidChildren(children) && <ArticleH2>{children}</ArticleH2>,
    [BLOCKS.HEADING_3]: (_, children) => isValidChildren(children) && <ArticleH3>{children}</ArticleH3>,
    [BLOCKS.OL_LIST]: (_, children) => isValidChildren(children) && <ArticleOrderedList>{children}</ArticleOrderedList>,
    [BLOCKS.UL_LIST]: (_, children) =>
      isValidChildren(children) && <ArticleUnorderedList>{children}</ArticleUnorderedList>,
    [BLOCKS.LIST_ITEM]: (node) => {
      const transformedChildren = documentToReactComponents(node, {
        renderMark: options.renderMark,
        renderNode: {
          [BLOCKS.PARAGRAPH]: (_node, children) => children,
          [BLOCKS.LIST_ITEM]: (_node, children) => children,
        },
      });
      return <ArticleListItem>{transformedChildren}</ArticleListItem>;
    },
    [BLOCKS.PARAGRAPH]: (_, children) => isValidChildren(children) && <ArticleParagraph>{children}</ArticleParagraph>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => <ArticleImage image={node.data.target} />,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      if (node?.data?.target?.__typename === 'ContentfulMuxVideo') {
        return <ArticleMuxVideo reference={node.data.target} />;
      }
      if (node?.data?.target?.__typename === 'ContentfulArticleFeature') {
        if (node?.data?.target?.type === 'YouTube Video') {
          return (
            <ArticleYouTubeVideo videoId={node.data.target.youTubeVideoId} aspectRatio={node.data.target.aspectRatio} />
          );
        }
        if (node?.data?.target?.type === 'TikTok Video') {
          return <ArticleTikTokVideo tiktokVideoId={node.data.target.tikTokVideoId} />;
        }
        // if (node.data.target.type === 'Carousel') {
        //   return <ArticleCarousel images={node.data.target.images} />;
        // }
        // if (node.data.target.type === 'Sticky Link') {
        //   return <StickyLink reference={node.data.target.productReference} />;
        // }
      }
    },
  },
};
const ArticleBody = ({ body, productLinks }) => {
  if (!body || !body?.raw) return null;

  const getGridTemplateRows = () => {
    const length = productLinks?.length;
    if (!length) return '1fr';
    return `repeat(${length + 1}, 1fr)`;
  };

  return (
    <article
      sx={{
        mb: ['4.8rem', '9.6rem'],
        display: 'grid',
        gridTemplateColumns: ['1fr', null, '1fr calc(66.8rem + (4rem * 2)) 1fr'],
        mx: 'auto',
        maxWidth: ['calc(66.8rem + (2.4rem * 2))', null, 'unset'],
      }}
    >
      {/* Empty div for left column */}
      <div sx={{ display: ['none', null, 'block'] }} />
      <div>{renderRichText(body, options)}</div>
      <aside
        sx={{
          gridColumn: ['1/2', null, '3/4'],
          display: 'grid',
          gridTemplateRows: ['1fr', null, getGridTemplateRows()],
          gridTemplateColumns: '1fr',
          position: 'relative',
        }}
      >
        <div sx={{ display: ['none', null, 'block'] }} />
        {productLinks?.map((productLink) => (
          <StickyLink key={productLink.id} productLink={productLink} />
        ))}
      </aside>
    </article>
  );
};

export default ArticleBody;

import LinkRenderer from '~/components/Generic/LinkRenderer';
import useSiteMetadataQuery from '~/hooks/graphql/queries/use-site-metadata';
const SocialLink = ({ children, socialPlatform, location, title }) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useSiteMetadataQuery();

  const platforms = {
    twitter: 'https://twitter.com/intent/tweet?url=',
    facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
    email: `mailto:?subject=${title}&body=`,
    linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  };

  const encodedUrl = encodeURI(`${siteUrl}${location?.pathname}`);
  const url = `${platforms[socialPlatform]}${encodedUrl}`;
  return (
    <LinkRenderer
      linkType="social"
      locationDetail="article"
      href={url}
      title={title}
      target="_blank"
      rel="noreferrer noopener"
      aria-label={title}
    >
      {children}
    </LinkRenderer>
  );
};

export default SocialLink;

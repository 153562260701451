const ArticleListItem = ({ children }) => {
  return (
    <li
      sx={{
        mb: '1.6rem',
        listStylePosition: 'inside',
        textIndent: '-1.6rem',
        paddingLeft: '1.6rem',
      }}
    >
      {children}
    </li>
  );
};

export default ArticleListItem;

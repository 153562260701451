import { Box } from 'theme-ui';

const ArticleYouTubeVideo = ({ videoId, aspectRatio = '16:9' }) => {
  const cssAspectRatio = aspectRatio.replace(':', '/');

  return (
    <Box
      sx={{
        aspectRatio: cssAspectRatio,
        px: ['2.4rem', '4rem'],
        mb: '4.8rem',
        maxWidth: aspectRatio === '16:9' ? '100%' : 'calc(32rem + (4rem * 2))',
        mx: 'auto',
      }}
    >
      <iframe
        title="YouTube Video"
        id="ytplayer"
        width="100%"
        height="100%"
        type="text/html"
        src={`https://www.youtube.com/embed/${videoId}?rel=0`}
        frameborder="0"
      />
    </Box>
  );
};

export default ArticleYouTubeVideo;

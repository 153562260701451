const ArticleH3 = ({ children }) => {
  return (
    <h3
      sx={{
        px: ['2.4rem', '4rem'],
        fontSize: ['1.6rem', '2rem'],
        lineHeight: 1,
        letterSpacing: '-0.02em',
        mb: '1.6rem',
        mt: '4.8rem',
      }}
    >
      {children}
    </h3>
  );
};

export default ArticleH3;

import { Box } from 'theme-ui';
import MuxVideoPlayer from '~/components/Generic/MuxPlayer';

const ArticleMuxVideo = ({ reference }) => {
  return (
    <Box
      sx={{
        px: ['2.4rem', '4rem'],
        position: 'relative',
        mb: '4.8rem',
      }}
    >
      <MuxVideoPlayer muxVideo={reference} showPoster />
    </Box>
  );
};

export default ArticleMuxVideo;

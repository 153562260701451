import { Grid, Flex, Text } from 'theme-ui';
import LinkRenderer from '~/components/Generic/LinkRenderer';
import ChevronLeftIcon from '~/assets/images/icons/chevron-left.svg';
import { alpha } from '@theme-ui/color';

import { useEffect, useState } from 'react';
import { getCategoryFromPath } from '~/utils/blog-category/get-category-from-path';
import useNavigationHeight from '~/context/NavigationContext/useNavigationHeight';

const CategoryBar = ({ location, categories }) => {
  const { navHeight } = useNavigationHeight();
  const topValue = `${navHeight ?? 60 - 1}px`;
  const basePreviousPage = '/blog/';
  const [previousPage, setPreviousPage] = useState({
    path: basePreviousPage,
    name: getCategoryFromPath({ path: basePreviousPage, capitalize: true }),
  });

  const allCategories = ['All', ...categories];

  const { pathname } = location;
  const isArticlePage = pathname.includes('/blog/article/');

  useEffect(() => {
    if (isArticlePage && window?.history?.state?.prevPath) {
      setPreviousPage({
        path: window.history.state.prevPath,
        name: getCategoryFromPath({ path: window.history.state.prevPath, capitalize: true }),
      });
    }
  }, []);

  return (
    <Flex
      data-testid="category-bar-wrapper"
      sx={(t) => ({
        position: 'sticky',
        top: topValue,
        height: 'var(--go-back-height)',
        left: 0,
        zIndex: 2,
        backgroundColor: [alpha('backgroundGrey', 0.6)(t), null, alpha('backgroundGrey', 0.8)(t)],
        backdropFilter: 'saturate(180%) blur(20px)',
        borderTop: `1px solid ${t.colors.cloud}`,
        borderBottom: `1px solid ${t.colors.cloud}`,
        py: '1.6rem',
        px: ['2.4rem', '4rem'],
        justifyContent: isArticlePage ? 'start' : 'center',
        width: '100%',
        overflow: 'auto',
      })}
    >
      <Grid
        sx={{
          gridAutoFlow: 'column',
          gap: '2.4rem',
        }}
      >
        {isArticlePage ? (
          <LinkRenderer
            data-testid="go-back-link"
            title={previousPage.name}
            to={previousPage.path}
            page={previousPage}
            sx={{ display: 'flex', gap: '1.6rem' }}
          >
            <ChevronLeftIcon sx={{ height: '1.6rem', width: '1.6rem' }} />
            <Text sx={{ fontSize: '1.4rem', fontWeight: 'medium', lineHeight: 1.15, letterSpacing: '-0.01em' }}>
              {`Back to ${previousPage.name}`}
            </Text>
          </LinkRenderer>
        ) : (
          allCategories.map((category) => {
            const isAll = category === 'All';
            const path = isAll ? '/blog/' : `/blog/category/${category.toLowerCase()}/`;
            const isActive = pathname === path;
            const title = isAll ? 'all categories' : `${category} category`;
            return (
              <LinkRenderer
                data-testid={`category-link-${category}`}
                title={title}
                sx={(t) => ({
                  color: isActive ? 'black' : t.colors.UIGray2,
                  fontWeight: 'medium',
                  fontSize: '1.4rem',
                  lineHeight: 1.15,
                  letterSpacing: '-0.01em',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-6px',
                    width: isActive ? '100%' : 0,
                    height: '2px',
                    left: '50%',
                    backgroundColor: 'black',
                    transform: 'translateX(-50%)',
                    transition: 'width 0.3s ease-in-out',
                  },
                })}
                key={category}
                to={path}
              >
                {category}
              </LinkRenderer>
            );
          })
        )}
      </Grid>
    </Flex>
  );
};

export default CategoryBar;
